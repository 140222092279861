import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    subHeader: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    subHeaderCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    subHeaderTitle: {
      marginBottom: theme.spacing(4),
      fontWeight: "bold",
    },
    subHeaderDescription: {
      marginBottom: theme.spacing(4),
    },
    subHeaderSocial: {},
    socialIcon: {
      margin: "4px",
    },
    root: {
      display: "flex",
      padding: theme.spacing(4, 2),
      maxWidth: 800,
      marginLeft: "auto",
      marginRight: "auto",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    center: {
      height: "50vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    centerDirection: {
      flexDirection: "column",
    },
    centerMessage: {
      marginBottom: theme.spacing(4),
    },
  })
);
