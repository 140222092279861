import React from "react";
import { BlogPost, RoutingProps, AuthProps } from "types/app";
import Markdown from "react-showdown";
import { showdownOptions } from "config/showdown";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getPost,
  // deletePost,
} from "services/blogActions";
import Comments from "3box-comments-react";
import appContext from "services/appContext";
import useStyles from "styles/pages/Read.styles";
import useAsyncEffect from "use-async-effect";
// import Paper from "@material-ui/core/Paper";
// import BookmarkShare from "components/BookmarkShare";
import timeConverter from "utils/timeConverter";
import Divider from "@material-ui/core/Divider";
// import LikeShare from "components/LikeShare";
import PostPagination from "components/PostPagination";

import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import IconButton from '@material-ui/core/IconButton';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

// import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { red } from '@material-ui/core/colors';
// import Tooltip from '@material-ui/core/Tooltip';

import {
  getLikes,
  checkLiked,
  addLike,
  removeLike,
  deletePost,
} from "services/blogActions";
import {
  addBookmark,
  removeBookmark,
  checkBookmarked,
} from "services/userActions";
import {
  handleFacebook,
  handleTwitter,
  handleLinkedIn,
} from "utils/socialShare";

interface Props {
  id: string;
}

const ReadPost: React.FunctionComponent<Props & RoutingProps & AuthProps> = ({
  id,
  handleRoute,
  handleLogin,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [post, setPost] = React.useState<BlogPost>({} as BlogPost);
  const [postId, setPostId] = React.useState<string>("");
  const [likes, setLikes] = React.useState<number>(0);
  const [isLiked, setIsLiked] = React.useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = React.useState<boolean>(false);
    const [commentsThread, setCommentsThread] = React.useState<string>();
  const { state, dispatch } = React.useContext(appContext);

  const {
    box,
    adminWallet,
    user: {
      walletAddress,

      isAdmin,
      loggedIn,
    },
    spaceName,
  } = state;

  useAsyncEffect(async () => {
    setLikes(await getLikes({ state, dispatch })(id));
    if (loggedIn) {
      const checkIsLiked = await checkLiked({ state, dispatch })(id);
      setIsLiked(checkIsLiked);
    }
    setIsBookmarked(await checkBookmarked({ state, dispatch })(id));

    if (id && id !== postId) {
      setCommentsThread(`comments-${id}`);
      setPostId(id);
      try {
        const newPost = await getPost({ state, dispatch })(id as string);
        setPost(newPost);
        setLoading(false);
      } catch (error) {
        handleRoute("");
      }
    } else if (!id) {
      handleRoute("");
    }
  }, [id, loggedIn]);

  const handleDelete = () => {
    if (
      window.confirm(
        "Do you really want to delete this post? This action is irreversible"
      )
    ) {
      setLoading(true);
      deletePost({ state, dispatch })(id as string).then((res) => {
        window.alert("Post successfully removed");
        handleRoute("");
      });
    }
  };

  //   const handleLogin = async () => {
  //     await login({ state, dispatch })();
  //   };

  // const handleEdit = () => {
  //   handleRoute("write", postId);
  // };

  const handleLike = async () => {
    if (isLiked) {
      await removeLike({ state, dispatch })(postId);
      setLikes((l) => l - 1);
      setIsLiked(false);
    } else {
      await addLike({ state, dispatch })(postId);
      setLikes((l) => l + 1);
      setIsLiked(true);
    }
  };

  const handleBookmark = async () => {
    if (isBookmarked) {
      await removeBookmark({ state, dispatch })(postId);
      setIsBookmarked(false);
    } else {
      await addBookmark({ state, dispatch })(postId);
      setIsBookmarked(true);
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.headerRow}>
            <Typography className={classes.title} variant="h4">
              {post.title}
            </Typography>
            {/*<BookmarkShare
              postId={postId}
              handleDelete={isAdmin ? handleDelete : undefined}
            />*/}
          </div>
          <Typography className={classes.description} variant="subtitle1">
            {post.description}
          </Typography>
          <div className={classes.dateAuthorRow}>
            <Typography className={classes.caption} variant="caption">
              {timeConverter(post.threadData?.timestamp as number)} • By{" "}
              {post.threadData?.author}
            </Typography>
          </div>
          <div className={classes.bodyContainer}>
            <Markdown
              dangerouslySetInnerHTML
              markdown={post.body}
              options={showdownOptions}
            />
          </div>
          <div className={classes.postBottom}>
            {isAdmin && (
              <>
                {/*<Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ marginRight: "8px" }}
                    onClick={handleEdit}
                  >
                    <EditRoundedIcon color="secondary" />
                  </IconButton>
                </Tooltip> */}
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={handleDelete}
                  style={{ marginRight: "8px" }}
                >
                  <DeleteForeverRoundedIcon style={{ color: red[700] }} />
                </IconButton>
              </>
            )}
            {likes > -1 && (
              <IconButton
                aria-label="like"
                size="small" style={{ marginRight: "8px" }}
                disabled={!loggedIn}
                onClick={handleLike}
              >
                {isLiked ? <FavoriteRoundedIcon /> : <FavoriteBorderRoundedIcon />} {likes}
              </IconButton>
            )}
            <div className={classes.postBottomRight}>
              <IconButton
                aria-label="facebook"
                size="small"
                disabled={!loggedIn}
                onClick={handleFacebook}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                aria-label="twitter"
                size="small"
                disabled={!loggedIn}
                onClick={handleTwitter}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                aria-label="linkedin"
                size="small"
                disabled={!loggedIn}
                onClick={handleLinkedIn}
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                aria-label="bookmark"
                size="small"
                disabled={!loggedIn}
                onClick={handleBookmark}
              >
                {isBookmarked ? <BookmarkRoundedIcon /> : <BookmarkBorderRoundedIcon />}
              </IconButton>
            </div>
          </div>
          <Divider className={classes.divider} />

          <PostPagination
            postId={postId}
            handleRoute={handleRoute}
            setLoading={setLoading}
          />
          {/* <Divider className={classes.divider} /> */}

          {/*<LikeShare postId={postId} />*/}
          <div className={classes.commentContainer}>
            <div className={classes.comments}>
              {commentsThread && box && (
                <Comments
                  spaceName={spaceName}
                  threadName={commentsThread}
                  adminEthAddr={adminWallet} // moderators[0]
                  box={box}
                  currentUserAddr={walletAddress}
                  loginFunction={handleLogin}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReadPost;
