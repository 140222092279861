import React from "react";
import { BlogPost, ThreadObject, RoutingProps, AuthProps } from "types/app";
import CKEditor from "components/CKEditor"
import {
  addPost,
  addDraft,
  getDrafts,
  removeDraft,
} from "services/blogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import appContext from "services/appContext";
import useStyles from "styles/pages/Write.styles";
import useAsyncEffect from "use-async-effect";
import { red } from '@material-ui/core/colors';

interface Props {
  id: string;
}

const WritePost: React.FunctionComponent<Props & RoutingProps & AuthProps> = ({
  id,
  handleRoute,
  handleLogin,
}) => {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(appContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [draftId, setDraftId] = React.useState<number>(-1);
  const [post, setPost] = React.useState<BlogPost>({
    tags: [""],
  } as BlogPost);

  useAsyncEffect(async () => {
    if (!state.user.loggedIn && !state.user.loading) {
      handleLogin();
    } else if (!state.user.isAdmin) {
      handleRoute("");
    } else if (id) {
      const index = parseInt(id, 10);
      const drafts = await getDrafts({ state, dispatch })();
      setDraftId(index);
      const draftPost = drafts.find(draft => draft.threadData?.postId === id) || {} as BlogPost;
      setPost(draftPost);
    }
    setLoading(state.user.loading);
  }, [id, state.user.loading]);

  const handleBodyChange = (body: string) => setPost({ ...post, body });
  const handleChange = ({
    target: { value, id },
  }: React.ChangeEvent<HTMLInputElement>) => {
    // setPost({ ...post, [id]: value.replace(":", "") });
    setPost({ ...post, [id]: value });
  };

  const handlePublish = async () => {
    setLoading(true);
    try {
      const postId = await addPost({ state, dispatch })(post);
      setPost({ ...post, threadData: { postId } as ThreadObject });
      handleRoute("read", postId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDraft = async () => {
    setLoading(true);
    try {
      await addDraft({ state, dispatch })(post);
      handleRoute("drafts");
    } catch (error) {
      console.error(error);
    }
  };

  const onDestroy = async () => {
    if (draftId > -1) {
      setLoading(true);
      try {
        await removeDraft({ state, dispatch })(draftId.toString());
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    setPost({} as BlogPost);
  };

  return (
    <div className={classes.root}>
      <div className={classes.editHeader}>
        <Typography variant="h1" className={classes.title}>
          Create post
        </Typography>
        <div className={classes.buttonRow}>
          <div className={classes.draftPublishGroup}>
            <Button
              variant="outlined"
              onClick={onDestroy}
              disabled={loading}
              style={{ color: red[700], marginRight: "16px" }}
            >
              Discard
            </Button>
            <Button
              variant="outlined"
              onClick={handleDraft}
              disabled={loading}
              style={{ marginRight: "16px" }}
            >
              Save draft
            </Button>
            <Button
              variant="outlined"
              onClick={handlePublish}
              disabled={loading}
              color="secondary"
            >
              Publish
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Typography className={classes.label}>Title</Typography>
          <TextField
            variant="outlined"
            id="title"
            value={post.title}
            onChange={handleChange}
            className={classes.textField}
            inputProps={{ maxLength: 120 }}
            color="secondary"
          />
          <Typography className={classes.label}>Short description</Typography>
          <TextField
            variant="outlined"
            id="description"
            value={post.description}
            onChange={handleChange}
            className={classes.textField}
            inputProps={{ maxLength: 240 }}
            color="secondary"
          />
          <Typography className={classes.label}>Media thumbnail (add image or youtube URL)</Typography>
          <TextField
            variant="outlined"
            id="thumbnail"
            value={post.thumbnail}
            onChange={handleChange}
            className={classes.textField}
            inputProps={{ maxLength: 2048 }}
            color="secondary"
            style={{ marginBottom: "56px" }}
          />
          {/*<Typography className={classes.label}>Post</Typography> */}
          <CKEditor value={post.body} onChange={handleBodyChange} />
        </>
      )}
    </div>
  );
};

export default WritePost;
