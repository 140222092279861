import React from "react";
import appContext from "services/appContext";
import useStyles from "styles/components/PostPagination.styles";
import Button from "@material-ui/core/Button";
import useAsyncEffect from "use-async-effect";
import { getPosts, getPost } from "services/blogActions";
import { RoutingProps } from "types/app";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from "@material-ui/core/Typography";

import timeConverter from "utils/timeConverter";
import clsx from 'clsx';

interface Props {
  postId: string;
  setLoading: (loading: boolean) => void;
}

const PostPagination: React.FunctionComponent<Props & RoutingProps> = ({
  postId,
  handleRoute,
  setLoading,
}) => {
  const classes = useStyles();
  const [neighborPosts, setNeighborPosts] = React.useState<{
    next: string;
    previous: string;
    nextData: any;
    previousData: any;
  }>({ next: "", previous: "", nextData: {}, previousData: {} });
  const { state, dispatch } = React.useContext(appContext);

  useAsyncEffect(async () => {
    const posts = await getPosts({ state, dispatch })();
    const index = posts.findIndex((post) => post.threadData!.postId === postId);

    if (index > -1) {
      const next = posts[index + 1] ? posts[index + 1]!.threadData!.postId : "";
      const previous = posts[index - 1]
        ? posts[index - 1]!.threadData!.postId
        : "";

      const nextData = next
        ? await getPost({ state, dispatch })(next as string)
        : {};
      const previousData = previous
        ? await getPost({ state, dispatch })(previous as string)
        : {};
      setNeighborPosts({
        next,
        previous,
        nextData,
        previousData,
      });
    }
  }, [postId]);

  const handlePrevious = () => {
    setLoading(true);
    handleRoute("read", neighborPosts.previous);
  };
  const handleNext = () => {
    setLoading(true);
    handleRoute("read", neighborPosts.next);
  };

  return (
    <div className={classes.root}>
      {/* Newer Post */}
      <div className={classes.paginationBlock}>
        <Button
          className={classes.arrowButton}
          color="secondary"
          onClick={handlePrevious}
          disabled={neighborPosts.previous === ""}
          startIcon={<ArrowBackIosIcon />}
        >
          Newer Post
        </Button>
        {(neighborPosts.previous !== "") && (
          <>
            <Typography variant="h6">
              {neighborPosts.previousData.title}
            </Typography>
            <Typography variant="caption">
              {timeConverter(neighborPosts.previousData.threadData?.timestamp as number)} • By{" "}
              {neighborPosts.previousData.threadData?.author}
            </Typography>
          </>
        )}
      </div>
      {/* Older Post */}
      <div className={clsx(classes.paginationBlock, classes.paginationBlockRight)}>
        <Button
          className={classes.arrowButton}
          color="secondary"
          onClick={handleNext}
          disabled={neighborPosts.next === ""}
          endIcon={<ArrowForwardIosIcon />}
        >
          Older Post
        </Button>
        {(neighborPosts.next !== "") && (
          <>
            <Typography variant="h6">
              {neighborPosts.nextData.title}
            </Typography>
            <Typography variant="caption">
              {timeConverter(neighborPosts.nextData.threadData?.timestamp as number)} • By{" "}
              {neighborPosts.nextData.threadData?.author}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default PostPagination;
