import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload-adapter';

interface Props {
  value: string;
  onChange: (value: string) => void;
}


const Editor: React.FunctionComponent<Props> = ({ value, onChange }) => {
  return (
    <CKEditor
        editor={ ClassicEditor }
        config={{
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        // data="<p>Hello from CKEditor 5!</p>"
        data={value}
        onInit={ (editor: any) => {
          editor.editing.view.change( (writer: any) => {
            writer.setStyle( 'height', '400px', editor.editing.view.document.getRoot() );
          });
        } }
        onChange={ ( event: any, editor: any ) => {
          const data = editor.getData();
          onChange(data);
        } }
        // onBlur={ ( event: any, editor: any ) => {
        //   console.log( 'Blur.', editor );
        // } }
        // onFocus={ ( event: any, editor: any ) => {
        //   console.log( 'Focus.', editor );
        // } }
    />
  );
}

export default Editor;
