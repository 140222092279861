import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "49px",
    },
    arrowButton: {
      borderRadius: 36,
      fontFamily: "OpenSans",
      fontWeight: 600,
    },
    paginationBlock: {
      maxWidth: "375px",
    },
    paginationBlockRight: {
      textAlign: "right",
    },
  })
);
