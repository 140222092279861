import React from "react";
import Typography from "@material-ui/core/Typography";
import { BlogPost, ThreadObject, RoutingProps } from "types/app";
import timeConverter from "utils/timeConverter";
import useStyles from "styles/components/PostPreview.styles";
import Paper from "@material-ui/core/Paper";
// import BookmarkShare from "components/BookmarkShare";
import appContext from "services/appContext";

import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
// import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

import useAsyncEffect from "use-async-effect";

import {
  getLikes,
  checkLiked,
  addLike,
  removeLike,
} from "services/blogActions";
import {
  addBookmark,
  removeBookmark,
  checkBookmarked,
} from "services/userActions";

const getYouTubeEmbedId = (url: string) => {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  var match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return 'error'
  }
}

interface Props extends RoutingProps {
  post: BlogPost;
  draft?: boolean;
  handleRemove?: (draftId: string) => void;
}

const PostPreview: React.FunctionComponent<Props> = ({
  post,
  draft = false,
  handleRemove,
  handleRoute,
}) => {
  const classes = useStyles();
  const [likes, setLikes] = React.useState<number>(0);
  const [isLiked, setIsLiked] = React.useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = React.useState<boolean>(false);
  const { title, description, thumbnail, threadData } = post;
  const { timestamp, postId } = threadData as ThreadObject;
  const date = timeConverter(timestamp);
  const { state, dispatch } = React.useContext(appContext);
  const {
    user: { loggedIn },
  } = state;

  useAsyncEffect(async () => {
    setLikes(await getLikes({ state, dispatch })(postId));
    if (loggedIn) {
      const checkIsLiked = await checkLiked({ state, dispatch })(postId);
      setIsLiked(checkIsLiked);
    }
    setIsBookmarked(await checkBookmarked({ state, dispatch })(postId));
  }, [loggedIn]);

  const handleRead = () => {
    handleRoute("read", postId);
  };

  const handleEdit = () => {
    handleRoute("write", postId);
  };

  const onRemoveDraft = () => {
    // set deleteId on Drafts && open DeleteModal
    if (handleRemove) {
      handleRemove(post.threadData?.postId as string);
    }
  };

  const handleLike = async () => {
    if (isLiked) {
      await removeLike({ state, dispatch })(postId);
      setLikes((l) => l - 1);
      setIsLiked(false);
    } else {
      await addLike({ state, dispatch })(postId);
      setLikes((l) => l + 1);
      setIsLiked(true);
    }
  };

  const handleBookmark = async () => {
    if (isBookmarked) {
      await removeBookmark({ state, dispatch })(postId);
      setIsBookmarked(false);
    } else {
      await addBookmark({ state, dispatch })(postId);
      setIsBookmarked(true);
    }
  };

  return (
    <Paper className={classes.container}>
      {/*<div className={classes.topRow}>
        <Typography className={classes.title} variant="h6"
          onClick={handleRead}
        >
          {title}
        </Typography>
        <BookmarkShare postId={post.threadData?.postId as string} />
      </div> */}
      {(thumbnail && thumbnail.length > 0) && (
        thumbnail.indexOf('youtube') > -1 ? (
          <iframe
            width="100%"
            height="375px"
            title="videoPreview"
            style={{ border: "none", borderRadius: "4px" }}
            src={`//www.youtube.com/embed/${getYouTubeEmbedId(thumbnail)}`}>
          </iframe>
        ) : (
          <div
            className={classes.thumbnail}
            style={{ backgroundImage: `url(${thumbnail})` }}
          ></div>
        )
      )}
      <Typography className={classes.title} variant="h6"
        onClick={draft ? handleEdit : handleRead}
      >
        {title}
      </Typography>
      <Typography className={classes.description} variant="subtitle1">
        {description}
      </Typography>
      <div className={classes.dateAuthorRow}>
        <Typography
          color="textSecondary"
          className={classes.caption}
          variant="caption"
        >
          {date} | By {threadData?.author}
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          {draft ? (
            <>
              <Tooltip title="Edit" arrow>
                <IconButton
                  aria-label="edit"
                  size="small"
                  style={{ marginRight: "8px" }}
                  onClick={handleEdit}
                >
                  <EditRoundedIcon color="secondary" />
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="remove"
                size="small"
                onClick={onRemoveDraft}
              >
                <DeleteForeverRoundedIcon style={{ color: red[700] }} />
              </IconButton>
            </>
          ) : (
            <>
              {likes > -1 && (
                <IconButton
                  aria-label="like"
                  size="small" style={{ marginRight: "8px" }}
                  disabled={!loggedIn}
                  onClick={handleLike}
                >
                  {isLiked ? <FavoriteRoundedIcon /> : <FavoriteBorderRoundedIcon />} {likes}
                </IconButton>
              )}
              <IconButton
                aria-label="bookmark"
                size="small"
                disabled={!loggedIn}
                onClick={handleBookmark}
              >
                {isBookmarked ? <BookmarkRoundedIcon /> : <BookmarkBorderRoundedIcon />}
              </IconButton>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default PostPreview;
