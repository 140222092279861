import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2, 9),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(-2.5),
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        padding: theme.spacing(2, 9),
      },
      borderTop: "1px solid #e8e8e8",
    },
    footerContainer: {
      maxWidth: "1000px",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
    },
    logosContainer: {
      display: "flex",
      alignItems: "center",
    },
    unstoppableLogo: {
      marginRight: theme.spacing(7),
    },
    creditContainer: {
      marginTop: "auto",
      marginBottom: "auto",
    },
    creditText: {
      color: theme.palette.primary.contrastText,
      fontFamily: "OpenSans",
      fontSize: 14,
    },
    hover: {
      "&:hover": {
        cursor: "pointer !important",
        textDecoration: "underline",
      },
    },
  })
);
