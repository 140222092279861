import * as React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "styles/components/DeleteModal.styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
// import Check from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import { red } from '@material-ui/core/colors';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

interface Props {
  open: boolean;
  onClose: () => void;
  handleRemove: () => void;
}

const DeleteModal: React.FunctionComponent<Props> = ({
  open,
  onClose,
  handleRemove,
}) => {
  const classes = useStyles();

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Paper className={classes.paper}>
        <div className={classes.modalContent}>
          <ErrorOutlineRoundedIcon className={classes.modalIcon} />
          <Typography variant="h5" className={classes.modalTitle}>
            Delete article?
          </Typography>
          <Typography variant="body1" className={classes.modalDescription}>
            Are you sure you want ot delete this article?
          </Typography>
          <div className={classes.modalButtons}>
            <Button
              variant="outlined"
              onClick={handleRemove}
              style={{ color: red[700] }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Paper>
    </Backdrop>
  );
};

export default DeleteModal;
