import React from "react";
import PostPreview from "components/PostPreview";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BlogPost, RoutingProps } from "types/app";
import appContext from "services/appContext";
import useStyles from "styles/pages/Drafts.styles";
import useAsyncEffect from "use-async-effect";
import Typography from "@material-ui/core/Typography";
import { getBookmarks } from "services/userActions";
import clsx from 'clsx';

const Bookmarks: React.FunctionComponent<RoutingProps> = ({ handleRoute }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [bookmarks, setBookmarks] = React.useState<BlogPost[]>([]);
  const { state, dispatch } = React.useContext(appContext);
  const { loggedIn } = state.user;

  React.useEffect(() => {
    if (!loggedIn) {
      handleRoute("");
    }
  }, [loggedIn, handleRoute]);

  useAsyncEffect(async () => {
    if (loggedIn) {
      const initialBookmarks = await getBookmarks({ state, dispatch })();
      setBookmarks(initialBookmarks);
      setLoading(false);
    }
  }, []);

  const handleRemove = (postId: string) => {
    const newBookmarks = bookmarks.filter(
      (bookmark) => bookmark.threadData?.postId !== postId
    );
    setBookmarks(newBookmarks);
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.center}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        bookmarks.length > 0 ? (
          <>
            <Typography variant="h1" className={classes.title}>
              Bookmarks
            </Typography>
            {bookmarks.map((bookmark: BlogPost, index: number) => (
              <PostPreview
                key={index}
                post={bookmark}
                handleRemove={handleRemove}
                handleRoute={handleRoute}
              />
            ))}
          </>
        ) : (
          <div className={clsx(classes.center, classes.centerDirection)}>
            <Typography variant="h1" className={classes.title}>
              Bookmarks
            </Typography>
            <Typography variant="body1" display="block" className={classes.centerMessage}>
              There is no bookmarks yet.
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default Bookmarks;
