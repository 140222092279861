import React from "react";
import PostPreview from "components/PostPreview";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { BlogPost, RoutingProps } from "types/app";
import { getPosts } from "services/blogActions";
import appContext from "services/appContext";
import useStyles from "styles/pages/Home.styles";
import useAsyncEffect from "use-async-effect";
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import CustomIcon from "components/CustomIcon";

const Home: React.FunctionComponent<RoutingProps> = ({ handleRoute }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [posts, setPosts] = React.useState<BlogPost[]>([]);
  const { state, dispatch } = React.useContext(appContext);

  const handleWebsite = () => handleSocials("website");
  const handleFacebook = () => handleSocials("facebook");
  const handleInstagram = () => handleSocials("instagram");
  const handleTwitter = () => handleSocials("twitter");
  const handleTelegram = () => handleSocials("telegram");
  const handleLinkedIn = () => handleSocials("linkedIn");
  const handleMedium = () => handleSocials("medium");
  const handleYouTube = () => handleSocials("youTube");

  const {
    title, blogDescription, socials,
    user: { loggedIn, isAdmin },
    theme: { palette },
  } = state;

  useAsyncEffect(async () => {
    if (!state.posts) {
      setLoading(true);
      const _posts = await getPosts({ state, dispatch })();
      setPosts(_posts);
      setLoading(false);
    } else {
      setPosts(state.posts);
      setLoading(false);
    }
  }, [state.posts]);

  const handleAddPost = () => {
    handleRoute("write");
  };

  const handleSocials = (id: string) => {
    const social = (socials as any)[id];
    const url = /^(http|https|ftp):/.test(social) ? social : `//${social}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={classes.subHeader}>
        <div className={classes.subHeaderCenter}>
          <Typography variant="h3" display="block" className={classes.subHeaderTitle}>
            {title}
          </Typography>
          <Typography variant="body1" display="block" className={classes.subHeaderDescription}>
            {blogDescription}
          </Typography>
          <div className={classes.subHeaderSocial}>
            {socials.hasWebsite && (
              <IconButton
                id="website"
                className={classes.socialIcon}
                onClick={handleWebsite}
                size="small"
                color="primary"
              >
                <LanguageIcon />
              </IconButton>
            )}
            {socials.hasFacebook && (
              <IconButton
                id="facebook"
                className={classes.socialIcon}
                onClick={handleFacebook}
                size="small"
                color="primary"
              >
                <FacebookIcon />
              </IconButton>
            )}
            {socials.hasInstagram && (
              <IconButton
                id="instagram"
                className={classes.socialIcon}
                onClick={handleInstagram}
                size="small"
                color="primary"
              >
                <InstagramIcon />
              </IconButton>
            )}
            {socials.hasTwitter && (
              <IconButton
                id="twitter"
                className={classes.socialIcon}
                onClick={handleTwitter}
                size="small"
                color="primary"
              >
                <TwitterIcon />
              </IconButton>
            )}
            {socials.hasTelegram && (
              <IconButton
                id="telegram"
                className={classes.socialIcon}
                onClick={handleTelegram}
                size="small"
                color="primary"
              >
                <TelegramIcon />
              </IconButton>
            )}
            {socials.hasLinkedIn && (
              <IconButton
                id="linkedIn"
                className={classes.socialIcon}
                onClick={handleLinkedIn}
                size="small"
                color="primary"
              >
                <LinkedInIcon />
              </IconButton>
            )}
            {socials.hasMedium && (
              <IconButton
                id="medium"
                className={classes.socialIcon}
                onClick={handleMedium}
                size="small"
                color="primary"
              >
                <CustomIcon
                  type="medium"
                  color={palette.primary.main}
                />
              </IconButton>
            )}
            {socials.hasYouTube && (
              <IconButton
                id="youTube"
                className={classes.socialIcon}
                onClick={handleYouTube}
                size="small"
                color="primary"
              >
                <YouTubeIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <div className={classes.root}>
        {loading ? (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        ) : (
          posts.length > 0 ? (
            posts.map((post: BlogPost, index: number) => (
              <PostPreview handleRoute={handleRoute} key={index} post={post} />
            ))
          ) : (
            <div className={clsx(classes.center, classes.centerDirection)}>
              <Typography variant="body1" display="block" className={classes.centerMessage}>
                There is no posts yet.
              </Typography>
              {(loggedIn && isAdmin) && (
                <Button
                  variant="outlined"
                  // className={classes.writeButton}
                  onClick={handleAddPost}
                  // onClick={handleLogin}
                  // color="secondary"
                  // startIcon={<AccountCircleRoundedIcon />}
                >
                  Write post
                </Button>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Home;
