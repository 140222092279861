import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      maxWidth: 960,
      boxShadow: "none",
      border: "1px solid #CACCCF",
    },
    topRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    title: {
      fontSize: 30,
      fontWeight: "bold",
      wordWrap: "break-word",
      cursor: "pointer",
    },
    dateAuthorRow: {
      display: "flex",
      alignItems: "center",
    },
    caption: {
      fontFamily: "OpenSans",
      fontSize: 14,
      wordBreak: "break-word",
    },
    description: {
      marginBottom: theme.spacing(3),
    },
    thumbnail: {
      marginBottom: "16px",
      borderRadius: "4px",
      backgroundSize: "cover",
      backgroundPosition: "center 50%",
      minHeight: "375px",
    },
    buttonRow: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
    containedButton: {
      display: "flex",
      alignItems: "center",
      width: 158,
      borderRadius: 25,
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    destroyButton: {
      fontFamily: "OpenSans",
      fontWeight: 600,
      color: theme.palette.error.main,
      borderRadius: 25,
      fontSize: 16,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: theme.spacing(2),
      },
    },
  })
);
